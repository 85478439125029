
import errorCodeStrings from '~/mixins/errorCodeStrings'
export default {
    name: 'Blank',
    mixins: [errorCodeStrings],
    middleware: ['auth'],
    mounted() {
        // Fetch error code string
        this.getErrorCodeStrings()
    },
}
